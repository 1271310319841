import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import CustomPagination from "../../Components/Blocks/CustomPagination/CustomPagination";
import ErrorComponent from "../../Components/Blocks/ErrorComponent/ErrorComponent";
import MainContainer from "../../Components/Blocks/MainContainer/MainContainer";
import MainLoader from "../../Components/Blocks/MainLoader/MainLoader";
import MediaListGrid from "../../Components/Blocks/MediaListGrid/MediaListGrid";
import NotFound from "../../Components/Blocks/NotFound/NotFound";
import RequestBlock from "../../Components/Blocks/RequestBlock/RequestBlock";
import SectionHead from "../../Components/Blocks/SectionHead/SectionHead";
import PageContentContainer from "../../Components/StyledComponents/PageContentContainer/PageContentContainer";
import useGetData from "../../Hooks/useGetData";
import { useScrollToTop } from "../../Hooks/useScrollToTop";
import ParticlesBackGround from "../../Components/Blocks/Particles/ParticlesBackGround";
import { Box, Stack } from "@mui/material";
import fallbackImage from "../../Assets/Images/default-actor.jpg";

type Props = {};

const SingleActor = (props: Props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();
  const query = queryString.parse(search);

  const { data, loading, error } = useGetData(
    `cast/show/${id}?page=${query.page}`,
    [id, query.page]
  );
  useScrollToTop([id]);

  if (loading) return <MainLoader />;
  if (error) return <ErrorComponent />;
  return (
    <>
      <ParticlesBackGround />
      <MainContainer>
        <PageContentContainer>
          <Stack direction="row" spacing={2} alignItems={"flex-end"}>
            <Box
              component="img"
              sx={{
                borderRadius: 1,
                width: 140,
                height: 200,
                objectFit: "cover",
                boxShadow: 1,
              }}
              src={data?.cast?.poster || fallbackImage}
              alt={data?.cast?.name}
            />
            <SectionHead
              color="#CEAFF5"
              title={data?.cast?.name}
              subTitle={t("singleActor")}
            />
          </Stack>
          {data?.cast?.list?.data && data?.cast?.list?.data?.length > 0 ? (
            <MediaListGrid cardsList={data?.cast?.list?.data} />
          ) : (
            <NotFound text={t("searchResults.noResults")} />
          )}{" "}
          {data?.data?.last_page && (
            <CustomPagination
              totalPages={data?.cast?.list?.last_page}
              from={data?.cast?.list?.from}
              to={data?.cast?.list?.to}
              total={data?.cast?.list?.total}
            />
          )}
        </PageContentContainer>
      </MainContainer>
      <RequestBlock />
    </>
  );
};

export default SingleActor;
