import { Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";
import MenuContainer from "../../../StyledComponents/MenuContainer/StyledMenuContainer";
import { useSelector } from "react-redux";
import { currency } from "../../../../Types/Types";
import { useTranslation } from "react-i18next";
import { createData } from "../../../../Services/DataServices";
import Cookies from "universal-cookie";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../../Helpers/toastHelper";
import { MenuItem } from "./Profile";

type Props = {};

const Currency = (props: Props) => {
  const { i18n } = useTranslation();

  const currencies = useSelector((state: any) => state.currency.currencies);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const handleShowMenu = () => {
    setShowMenu(true);
  };
  const handleCloseMenu = () => {
    setShowMenu(false);
  };
  const cookies = new Cookies();
  const handleChangeCurrency = async (
    currencyId: any,
    currencyName: string
  ) => {
    const formData = new FormData();
    formData.append("currency_id", currencyId);
    await createData("user/set_currency", formData)
      .then((res) => {
        cookies.set("currency", currencyName, { path: "/" });
        if(currencyName === "Kuwaiti Dinar"){
          cookies.set("currency_ar", "دينار كويتي", { path: "/" })
          cookies.set("currency_en", "KWD", { path: "/" })
        } else if ( currencyName === "USD") {
          cookies.set("currency_ar", "دولار أمريكي", {path: "/"})
          cookies.set("currency_en", "USD", {path: "/"})
        } else if (currencyName === "Saudi Riyal") {
          cookies.set("currency_ar", "ريال سعودي", {path: "/"})
          cookies.set("currency_en", "SAR", {path: "/"})
        }
        displayToastSuccess(res?.data?.message);
        handleCloseMenu();
        // console.log(cookies.get('currency'), cookies.get('currency_ar'), cookies.get('currency_en'));
        
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err: any) => {
        displayToastErrorApi(err?.data?.message);
      });
  };
  return (
    <StyledIcon
      onMouseEnter={handleShowMenu}
      onMouseLeave={handleCloseMenu}
      sx={{
        backgroundColor: "white",
        "& svg": {
          color: "primary.main",
        },
        "&:hover": {
          backgroundColor: "white",
        },
      }}
    >
      <FaDollarSign />
      {showMenu && currencies?.length > 0 && (
        <MenuContainer className="menu">
          {currencies.map((item: currency, index: number) => {
            return (
              <MenuItem
                key={index}
                title={i18n.language === "en" ? item.name : item.name_ar}
                action={() => handleChangeCurrency(item.id, item.name)}
              />
            );
          })}
        </MenuContainer>
      )}
    </StyledIcon>
  );
};

export default Currency;
