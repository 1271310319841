import { Box, CircularProgress, Stack } from "@mui/material";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getData } from "../../../Services/DataServices";
import { cartActions } from "../../../store/cart-slice";

// Custom component to wrap the PayPalButtons and show loading spinner
type PaypalButtonProps = {
  handleSubmit: () => Promise<{
    data: {
      Data: {
        invoiceId: string;
      };
    };
  }>;
};
const PaypalButton = ({ handleSubmit }: PaypalButtonProps) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const style = { layout: "vertical" };
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    handleSubmit().then((res) => {
      // console.log(res?.data?.Data?.invoiceId);
      setOrderId(res?.data?.Data?.invoiceId);
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const createOrder = async (data: any, actions: any) => {
    return orderId;
  };
  const navigate = useNavigate();

  const onApprove = async (data: any, actions: any) => {
    try {
      setLoading(true);
      const response = await getData(`pp_response/${data.orderID}`);
      navigate("/purchases");
      console.log(response);
    } catch (err: any) {
      toast.error(err?.response?.data?.message, {
        toastId: "paypal-error",
      });
    } finally {
      setLoading(false);
      dispatch(cartActions.handleCloseCheckOutModal());
      dispatch(cartActions.clearCart());
    }
  };

  if (orderId === "" || loading || isPending)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress size={32} />
      </Box>
    );

  return (
    <Stack sx={{ width: "100%" }}>
      {!isPending && (
        <PayPalButtons
          disabled={false}
          forceReRender={[style]}
          fundingSource={undefined}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      )}
    </Stack>
  );
};

export default PaypalButton;
