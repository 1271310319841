import React from "react";
import TiltAnimation from "../../Animation/TiltAnimation";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { singleItemType } from "../../../Types/Types";
import { useTranslation } from "react-i18next";
import imagePlaceholder from "../../../Assets/Images/default-movie.jpg";
import useOrderUrl from "../../../Hooks/useOrderUrl";
import { useParams } from "react-router-dom";

const SmallOrderCard = ({
  poster,
  name_en,
  name_ar,
  id,
  itemType,
  type,
  mediaType,
  product_id,
}: singleItemType) => {
  const { i18n } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  // console.log("orderId", orderId);
  // console.log("itemType", itemType);
  // console.log("id", id);
  // console.log("type", type);
  const { url, loading, error, getUrl } = useOrderUrl(
    orderId || "",
    itemType,
    mediaType,
    String(product_id)
  );
  return (
    <Box sx={{ width: "fit-content", position: "relative" }}>
      <TiltAnimation>
        <Card
          sx={{
            overflow: "hidden",
            position: "relative",
            borderRadius: 4,
            backgroundColor: "transparent",
            boxShadow: "14px 0px 30px #00000054",
            width: "200px",
            height: "auto"
          }}
        >
          <CardActionArea
            onClick={() => {
              getUrl();
            }}
            sx={{
              zIndex: 2,
            }}
          >
            <CardMedia
              component="img"
              height="250"
              image={poster}
              alt={name_en}
              onError={(e) => {
                e.currentTarget.src = imagePlaceholder;
              }}
            />
            <CardContent
              sx={{
                position: "relative",
                // bottom: 0,
                // left: "50%",
                // transform: "translateX(-50%)",
                width: "100%",
                overflow: "hidden",
                padding: "8px",
                minHeight: "65px",
                backgroundImage:
                  " linear-gradient(180deg, rgb(83 78 78 / 6%) 0%, rgb(0 0 0 / 92%) 100%)",
              }}
            >
              <Typography
                variant="h5"
                component="h6"
                sx={{
                  color: "white",
                  whiteSpace: "wrap",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                  fontSize: {
                    mobile: "14px",
                    md: "16px",
                  },
                }}
              >
                {i18n.language === "en"
                  ? name_en || name_ar
                  : name_ar || name_en}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </TiltAnimation>
    </Box>
  );
};

export default React.memo(SmallOrderCard);
